import React, { useState } from 'react';
import styles from './Header.module.scss';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
      <svg width="200" height="60" xmlns="http://www.w3.org/2000/svg">
        <text x="0" y="40" fontFamily="Arial, sans-serif" fontSize="25" fill="#fff">
          Angel Vidal
        </text>
      </svg>
      </div>
      <nav className={`${isMenuOpen ? styles.open : styles.hidden} ${styles.nav} md:flex md:items-center md:gap-8`}>
        <ul className={styles.navList}>
          <li><a href="#about" onClick={closeMenu}>About</a></li>
          <li><a href="#projects" onClick={closeMenu}>Projects</a></li>
          <li><a href="#contact" onClick={closeMenu}>Contact</a></li>
        </ul>
      </nav>
      <div className={styles.mobileMenuIcon} onClick={toggleMenu}>
        <div className={styles.bar}></div>
        <div className={styles.bar}></div>
        <div className={styles.bar}></div>
      </div>
    </header>
  );
};

export default Header;
